export const Sales = () => {
  return (
    <div className="w-full  text-start  md:mt-0 mt-[10px] relative h-full flex flex-col justify-center items-center ">
      <div className="flex md:w-3/4 p-3 flex-col flex justify-center items-center">
        <img
          src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/ae2nwzhwdj37s4kzevzh"
          alt="analytics"
          className="md:w-[50vw] w-full"
        />

        <div className="p-7 flex flex-col gap-5 ">
          <p className="text-[26px] font-[600] md:ml-10">Never miss a sale</p>
          <div className="grid lg:grid-cols-3 2xl:grid-cols-3 md:grid-cols-1 md:gap-1 gap-5  ">
            <div className="flex flex-col justify-start items-start gap-4">
              <div className="w-full flex flex-row justify-start items-center gap-2">
                <div className="h-[20px] border border-primary w-1 bg-primary md:ml-10"></div>
                <p className="">Order Notifications</p>
              </div>
              <p className=" md:ml-10">
                Get real-time alerts on WhatsApp for every new order. Stay
                updated on what’s selling, manage customer requests, and ensure
                prompt fulfillment, all from the convenience of your WhatsApp
                chat.
              </p>
            </div>
            <div className="flex flex-col justify-start items-start gap-4">
              <div className="w-full flex flex-row justify-start items-center gap-2">
                <div className="h-[20px] border border-primary w-1 bg-primary md:ml-10"></div>
                <p className="">Payment Notifications</p>
              </div>
              <p className=" md:ml-10">
                Receive instant WhatsApp notifications whenever a payment is
                completed. Keep track of your sales and cash flow effortlessly,
                so you can stay informed and focused on growing your business.
              </p>
            </div>
            <div className="flex flex-col justify-start items-start gap-4">
              <div className="w-full flex flex-row justify-start items-center gap-2">
                <div className="h-[20px] border border-primary w-1 bg-primary md:ml-10"></div>
                <p className=""> Notifications</p>
              </div>
              <p className=" md:ml-10">
                Stay in the loop with all your store updates on WhatsApp. From
                new orders and payments to stock alerts and customer messages,
                get all the important notifications directly on your phone, no
                matter where you are.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
