import { Hero } from "../Components/hero";
import { Analytics } from "../Components/sections/analytics";
import { Flow } from "../Components/sections/flow";
import { Sales } from "../Components/sections/sales";
import { Pricing } from "../Components/sections/pricing";
import { Store } from "../Components/sections/store";

export const Landing = () => {
  return (
    <div className="flex flex-col w-full  gap-4 flex-col flex">
      <Hero />
      <Store />
      <Sales />
      <Analytics />
      <Flow />

      <Pricing />
    </div>
  );
};
