import { Outlet } from "react-router-dom";
import { Header } from "./header";
import { Footer } from "./sections/footer";

export const Controller = () => {
  return (
    <div className="relative">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};
