export const Flow = () => {
  return (
    <div className="w-full  text-start md:p-10 p-6 md:mt-0 mt-[70px] relative h-full flex flex-row justify-center items-center ">
      <div className="md:w-3/4 h-auto  rounded-md flex md:flex-row flex-col">
        <img
          src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/s7ntjqcw2qbeqdb58uxe"
          alt="flow"
          className="md:w-[50vw] w-full"
        />
        <div className="flex flex-col gap-7">
          <p className="text-[26px] font-[600] md:ml-10">
            Supercharge your workflow
          </p>

          <div className="w-full flex flex-row justify-start items-center gap-2">
            <div className="h-[20px] border border-primary w-1 bg-primary md:ml-10"></div>
            <p>Save time</p>
          </div>
          <p className="md:w-full md:ml-10">
            Craft personalized marketing campaigns using our intuitive
            drag-and-drop builder. From email sequences to social media posts,
            create impactful messaging without any technical know-how
          </p>

          <div className="w-full flex flex-row justify-start items-center gap-2">
            <div className="h-[20px] border border-primary w-1 bg-primary md:ml-10"></div>
            <p>Automation</p>
          </div>
          <p className="w-full md:ml-10">
            Deploy your campaigns with a single click and let our automation
            handle the rest. From lead nurturing to customer follow-ups,
            automate every step to maximize efficiency and results.
          </p>
        </div>
      </div>
    </div>
  );
};
