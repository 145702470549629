import axios from "axios";
import { useEffect, useState } from "react";
import BlogCard from "../Components/blog";
import { LuLoader2 } from "react-icons/lu";
export const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [blogOfTheDay, setBlogOfTheDay] = useState("");

  const getDayOfYear = (date: Date): number => {
    const start = new Date(date.getFullYear(), 0, 0);
    const diff: number = date.getTime() - start.getTime(); // Use getTime() to explicitly get the time difference in ms
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
  };
  const getRandomBlogIndex = (date: Date, length: number) => {
    const dayOfYear = getDayOfYear(date);
    return dayOfYear % length;
  };
  const init = async () => {
    await axios
      .get("https://blobcart.pythonanywhere.com/posts/")
      .then((res: { data: [] }) => {
        const today = new Date();
        setBlogs(res.data.reverse());
        const blogIndex = getRandomBlogIndex(today, res.data.length);
        setBlogOfTheDay(res.data[blogIndex]);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <div className="flex flex-col gap-4 flex-col flex">
      <div className="flex flex-col h-full md:min-h-screen md:w-1/2 w-full  m-auto  ">
        <div className="flex flex-col justify-center gap-4 items-center  w-full  mt-[130px]">
          <p className="font-[500] text-xl text-grey">Latest Blogs</p>
        </div>

        {loading ? (
          <div className="flex w-full justify-center items-center mt-10   ">
            <LuLoader2 className="w-4 h-4 animate-spin text-dark" />
          </div>
        ) : (
          <div className="mx-auto md:grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-2  gap-10   flex flex-col md:justify-start md:items-start justify-center items-center mt-5 mb-10 ">
            {blogs.map(
              (blog: {
                pk: string;

                title: string;
                image: string;
                post: string;
                date: Date;
              }) => (
                <BlogCard key={blog.pk} item={blog} />
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};
