export const Button = ({
  title,
  style,
  action,
}: {
  title: string;
  action?: () => void;
  style?: string;
}) => {
  return (
    <div
      onClick={action}
      className={
        style
          ? style
          : "p-2 px-4 cursor-pointer rounded-full justify-center items-center  bg-dark text-white"
      }
    >
      <p>{title}</p>
    </div>
  );
};
