export const Analytics = () => {
  return (
    <div className="w-full  text-start md:p-10 p-6  relative h-full flex flex-col justify-center items-center ">
      <div className="flex card rounded-md m-auto md:w-1/2 flex-col flex justify-center text-start items-start gap-2">
        <div className="p-5 flex flex-col justify-start items-start gap-3">
          <p className="text-[26px] text-start font-[600]">
            Powerful analytics
          </p>
          <div className="w-full flex flex-row justify-start items-center gap-2">
            <div className="h-[20px] border border-primary w-1 bg-primary"></div>
            <p> Advanced features packed in a simple solution</p>
          </div>
          <p className="md:w-full ">
            Unlock valuable insights into your business with powerful analytics
            tools. Track sales, customer behavior, and product performance in
            real time, all through a user-friendly dashboard that makes
            data-driven decisions a breeze
          </p>
        </div>
        <img
          src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/oqndjxp3wlibxlncf33z"
          alt="analytics"
          className="md:w-[50vw]"
        />
      </div>
    </div>
  );
};
