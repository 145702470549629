import dayjs from "dayjs";
import "dayjs/locale/en";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GiPadlock } from "react-icons/gi";
import { LuLoader2 } from "react-icons/lu";
import { Button } from "../Components/button";
export default function Template() {
  const [title, setTitle] = useState("");
  const [loader, setLoader] = useState(true);
  const [post, setPost] = useState("");
  const [timetoread, setTimeToRead] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [image, setImage] = useState("");
  const [writer, setWriter] = useState("");
  const [img, setPhotosResponse] = useState(null);
  const { id } = useParams();
  const slug = id ? id : "";
  /**  */
  const getData = async () => {
    console.log(slug.replace(/-/g, "+"));
    await axios
      .get(
        `https://blobcart.pythonanywhere.com/posts/?search=${slug.replace(
          /-/g,
          " "
        )}`
      )
      .then((res) => {
        setDate(dayjs(res.data[0].date).format("ddd MMM DD, YYYY"));
        setTitle(res.data[0].title);
        setTimeToRead(res.data[0].timetoread);
        setPost(res.data[0].post.replaceAll('"', ""));
        setWriter(res.data[0].writer);
        setLocation(res.data[0].location);
        setImage(res.data[0].image);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{title}</title>
        <meta property="og:title" content={title} />
        {img && <meta property="og:image" content={img} />}
        <meta property="og:type" content="article" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      {loader ? (
        <div className="flex w-full justify-center items-center  md:h-screen ">
          <LuLoader2 className="w-4 h-4 animate-spin text-dark" />
        </div>
      ) : (
        <div className="flex flex-col gap-4 flex-col flex ">
          <div className="flex relative flex-col mt-[100px] justify-center  items-center w-full md:min-h-screen h-full md:px-10  p-5">
            <div className="flex  w-full flex-col m-auto justify-center items-center gap-4  m-auto md:mt-8">
              <h1 className="  md:text-5xl text-3xl font-[500]">
                {title.replaceAll('"', "")}
              </h1>

              <div className="flex justify-center items-center  gap-2 flex-row">
                <div className="flex flex-col justify-center items-center gap-1">
                  <p className="text-grey text-md">
                    Published by <u>{writer}</u> on {date.replaceAll('"', "")}
                  </p>
                  <div className="flex justify-center items-center  gap-2 flex-row">
                    <GiPadlock />
                    <p className="text-grey text-md">
                      {timetoread.replaceAll('"', "")} read ·{" "}
                      {location.replaceAll('"', "")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative mx-auto mt-2  w-full max-w-4xl rounded-md ">
                <img
                  alt="cover"
                  className="cover w-full h-[100%]"
                  src={image ? image : ""}
                />
              </div>
              <div className="max-w-4xl md:mx-0 mx-auto flex  mb-6 ">
                <p
                  className="py-2 leading-loose"
                  style={{ width: "100%" }}
                  dangerouslySetInnerHTML={{
                    __html: post.replace(/\\n/g, "<br>"),
                  }}
                ></p>
              </div>
            </div>
            <div className="max-w-4xl gradient flex flex-col p-8 card  py-4 rounded-md gap-1   bg-dark justify-start items-center text-white">
              <>
                <div
                  onClick={() => {
                    navigate("/");
                  }}
                  className="flex  gap-1 flex-row justify-start items-center cursor-pointer "
                >
                  <img
                    src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/rq5oa5gjbwztgkmuzg1k"
                    alt="logo"
                    className="w-11 h-11"
                  />

                  <p className="font-[500]">Blobcart</p>
                </div>
                <p className=" text-center md:text-4xl text-2xl text-white font-[800]">
                  Your e-commerce patner
                </p>
                <img src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/bb9qdjvmn8ymirficxfo" />

                <p className=" text-center text-white text-xl font-[500]">
                  Blobcart links to your Whatsapp so that you can simplify
                  ordering and turn conversations to sales.
                </p>
                <Button
                  title="Get started"
                  action={() => {
                    window.open("https://platform.blobcart.com", "_self");
                  }}
                  style={
                    "p-2 px-4 max-w-[180px] h-[40px] rounded-md justify-center items-center mt-2  flex border-dark border-opacity-10 border  bg-white w-full text-dark hover:shadow-md cursor-pointer"
                  }
                />
                <div className="flex flex-row justify-center items-center  mt-6 gap-4"></div>
              </>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
