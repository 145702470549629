import React from "react";

export default function FooterCard({
  item,
}: {
  item: { title: string; links: { url: string; title: string }[] };
}) {
  return (
    <div className="flex flex-col justify-evenly items-start py-4 gap-3">
      <p className="text-dark  font-[500] ">{item.title}</p>
      {item.links.map((link) => (
        <p
          key={link.url}
          onClick={() => (window.location.href = link.url)}
          className=" cursor-pointer hover:text-gray"
        >
          {link.title}
        </p>
      ))}
    </div>
  );
}
