import dayjs from "dayjs";
import "dayjs/locale/en";

import { TbBulb } from "react-icons/tb";
export default function BlogCard(props: {
  item: { pk: string; title: string; image: string; post: string; date: Date };
  highlight?: boolean;
}) {
  return (
    <div
      onClick={() => {
        const url = `/blog/${props.item.title
          .replace(/"/g, "")
          .replace(/\s+/g, "-")}`;
        window.location.href = url.toLowerCase();
      }}
      className="w-[350px] relative flex flex-col justify-start items-start cursor-pointer card hover:shadow-md rounded-md"
    >
      {props.highlight && (
        <div className="absolute gap-2 p-2 top-2 right-2 bg-secondary rounded-md flex flex-row justify-center items-center">
          <TbBulb />
          <p>Today's highlight</p>
        </div>
      )}
      <img
        src={props.item.image}
        className="w-full  hover:shadow-xl"
        alt="blog-cover"
      />
      <div className="flex flex-row items-start justify-center mt-4 gap-2 p-3">
        <div className="flex flex-col items-start justify-center gap-1">
          <p className="text-sm text-grey">
            {dayjs(props.item.date).format("ddd MMM DD, YYYY")}
          </p>
          <p className="font-[500] text-xl">{props.item.title}</p>

          <p className="text-sm text-grey">
            {props.item.post
              .replaceAll('"', "")
              .replace(/<\/?[^>]+(>|$)/g, "")
              .slice(0, 100)}
            ...
          </p>
        </div>
      </div>
    </div>
  );
}
