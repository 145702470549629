import { Button } from "../Components/button";

export const Lost = () => {
  return (
    <div className="md:h-[calc(100vh-200px)] h-screen flex justify-center items-center flex-col gap-2">
      <p>This is awkward...😅</p>
      <p>We can't find the page you're looking for.</p>
      <Button
        title="Go home"
        action={() => {
          window.location.href = "/";
        }}
      />
    </div>
  );
};
