import { TiTick } from "react-icons/ti";
import { Button } from "../button";
import { FaToggleOn, FaToggleOff } from "react-icons/fa";
import { useState } from "react";

export const Pricing = () => {
  const [yearly, setYearly] = useState(true);
  return (
    <div className="w-full flex-col flex gap-2">
      <p className="text-center text-2xl font-[500]">
        Supercharge your sales growth on{" "}
        <span className="relative text-3xl font-[500]">
          Whatsapp{" "}
          <img
            src="https://res.cloudinary.com/do2czykkq/image/upload/f_auto,q_auto/bfgri3hnudtu3fyyaqd6"
            alt="whatsapp-logo"
            className="absolute w-[30px] h-[30px] right-[-25px] bottom-5"
          />
        </span>
      </p>
      <p className="text-center text-grey font-[500]">
        Whether you're a clothes shop, a grocery or a large enterprise, we have
        plans tailored to your specific demands.
      </p>
      <div className="flex w-full justify-center items-center">
        <div
          onClick={() => {
            setYearly(!yearly); // Toggle the state
          }}
        >
          {yearly ? (
            <div className="flex flex-row items-center justify-start gap-1">
              <p className="line-through">Monthly</p>
              <FaToggleOn className="w-7 h-7 cursor-pointer" />
              <p>
                Yearly
                <span className="font-[500] text-blue">(Save 10% more)</span>
              </p>
            </div>
          ) : (
            <div className="flex flex-row items-center justify-start gap-1">
              <p>Monthly</p>
              <FaToggleOff className="w-7 h-7 cursor-pointer" />
              <p className="line-through ">
                Yearly
                <span className="font-[500] text-blue">(Save 10% more)</span>
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="justify-center items-center flex w-full p-4 md:flex-row flex-col md:gap-2 gap-9">
        <div className="card  rounded-md p-10  flex flex-col justify-center items-center shadow-md w-full md:w-[350px] gap-2 relative">
          <p>Basic</p>
          <span className="flex flex-row justify-start items-center gap-1">
            <p className="line-through font-[500] text-xl">$65</p>
            <p className="text-3xl font-[500]  font-bold">
              {" "}
              {yearly ? "$39/mo" : "$45/mo "}
            </p>
          </span>
          <p className="text-blue font-[500] ">
            {yearly ? "Save 40%" : "Save 30%"}
          </p>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">Whatsapp Ordering</p>
          </div>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">1 seat</p>
          </div>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">Online Storefront</p>
          </div>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">Unlimited Images</p>
          </div>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">Checkout</p>
          </div>
          <Button
            title="Get started"
            action={() => {
              window.open("https://platform.blobcart.com", "_self");
            }}
            style={
              "p-2 px-4  rounded-md justify-center items-center mt-2  flex border-dark border-opacity-10 border  bg-dark w-full text-white hover:shadow-md cursor-pointer"
            }
          />
        </div>

        <div className="border border-blue  rounded-md p-10 flex flex-col justify-center items-center shadow-md w-full md:w-[350px] gap-2 relative">
          <div className="absolute bg-blue text-white rounded-md p-2 top-[-15px]">
            <p>Popular</p>
          </div>
          <p>Pro</p>
          <span className="flex flex-row justify-start items-center gap-1">
            <p className="line-through font-[500] text-xl">$120</p>
            <p className="text-3xl font-[500]  font-bold">
              {yearly ? "$48/mo" : "$60/mo "}
            </p>
          </span>
          <p className="text-blue font-[500] ">
            {" "}
            {yearly ? "Save 60%" : "Save 50%"}
          </p>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">Whatsapp Ordering</p>
          </div>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">Unlimited seats</p>
          </div>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">Online Storefront</p>
          </div>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">Unlimited Images</p>
          </div>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">Checkout</p>
          </div>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">Analytics</p>
          </div>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">Premium Support</p>
          </div>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">Blobcart branding removal</p>
          </div>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">Whatsapp campaigns(Coming Soon)</p>
          </div>
          <div className="flex flex-row justify-start flex items-center w-full">
            <TiTick className="text-blue" />
            <p className="text-sm">Workflows(Coming Soon)</p>
          </div>
          <Button
            title="Get started"
            action={() => {
              window.open("https://platform.blobcart.com", "_self");
            }}
            style={
              "p-2 px-4  rounded-md justify-center items-center mt-2  flex border-dark border-opacity-10 border  bg-dark w-full text-white hover:shadow-md cursor-pointer"
            }
          />
        </div>
        <div className="card  mb-auto rounded-md p-10 flex flex-col justify-center items-center shadow-md w-full md:w-[350px] gap-2 relative">
          <p>Enterprise</p>

          <p className="text-sm">
            For large business that require personalized services and expert
            support services.
          </p>

          <Button title="Contact Us" />
        </div>
      </div>
    </div>
  );
};
