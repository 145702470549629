import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { TermAndConditions } from "./Pages/termsandconditions";
import { Controller } from "./Components/controller";
import { PrivacyPolicy } from "./Pages/privacypolicy";
import { Landing } from "./Pages/landing";
import { Lost } from "./Pages/lost";
import { Blog } from "./Pages/blog";
import Template from "./Pages/template";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const helmetContext = {};
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider context={helmetContext}>
        <Routes>
          <Route element={<Controller />}>
            <Route path="/" element={<Landing />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<Template />} />
            <Route path="/*" element={<Lost />} />
            <Route
              path="/terms-and-conditions"
              element={<TermAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Route>
        </Routes>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
